import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['amount', 'email', 'postal', 'buttonText']
  static values = { type: String }

  connect() {
    const id = `voucher-type-${this.typeValue}`
    document.getElementById(id).checked = true
    this.doToggleForm(this.typeValue)
  }

  toggleForm(ele) {
    this.doToggleForm(ele.currentTarget.value)
  }

  doToggleForm(type) {
    this.emailTarget.classList.add('hidden')
    this.postalTarget.classList.add('hidden')
    this[`${type}Target`].classList.remove('hidden')
  }

  updateAmount(ele) {
    const amountValue = parseInt(ele.currentTarget.value)
    this.updateButtonText(amountValue)
  }

  selectValue(ele) {
    const amountValue = parseInt(ele.currentTarget.value)
    if (isNaN(amountValue)) {
      return
    }
    this.amountTarget.value = amountValue
    this.updateButtonText(amountValue)
  }

  updateButtonText(amountValue) {
    this.buttonTextTarget.innerHTML = `Add £${amountValue} voucher to basket`
  }
}
