export default function useDomHelper(): {
  findAndReplace: (data: string, ids: string[]) => void
  hide: (id: string) => void
  setLoading: (ids: string[], showSpinner: boolean) => void
  show: (id: string) => void
  toggleClass: (ids: string[], cssClass: string, add: boolean) => void
} {
  function hide(id: string) {
    document.querySelector(id)?.classList.add('hidden')
  }

  function show(id: string) {
    document.querySelector(id)?.classList.remove('hidden')
  }

  function findAndReplace(data: string, ids: string[]): void {
    const el = document.createElement('html')
    el.innerHTML = data
    ids.forEach((id) => {
      const newElement = el.querySelector(id)
      const existingElement = document.querySelector(id)
      if (existingElement && newElement) {
        existingElement.replaceWith(newElement)
      } else {
        console.log(`findAndReplace failed ${id}`)
      }
    })
  }

  function toggleClass(ids: string[], cssClass: string, add: boolean) {
    ids.forEach((id) => {
      const el = document.querySelector(id)
      if (el) {
        add ? el.classList.add(cssClass) : el.classList.remove(cssClass)
      }
    })
  }

  function setLoading(ids: string[], showSpinner: boolean) {
    toggleClass(ids, 'loading', showSpinner)
  }

  return {
    findAndReplace,
    hide,
    setLoading,
    show,
    toggleClass
  }
}
