import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'readMoreLink', 'readLessLink']
  static values = { lines: Number }

  connect() {
    if (this.contentTarget.scrollHeight > this.contentTarget.clientHeight) {
      this.readMoreLinkTarget.classList.remove('hidden')
    }
  }

  open() {
    this.contentTarget.classList = []
    this.readMoreLinkTarget.classList.add('hidden')
    this.readLessLinkTarget.classList.remove('hidden')
  }

  close() {
    this.contentTarget.classList = [`overflow-y-hidden line-clamp-${this.linesValue}`]
    this.readMoreLinkTarget.classList.remove('hidden')
    this.readLessLinkTarget.classList.add('hidden')
  }
}
