export default function useGA4Helper(): {
  GA4TrackPaymentInfo: (paymentType: string) => void
} {
  function GA4TrackPaymentInfo(paymentType: string) {
    const checkoutPayload = {
      event: 'add_payment_info',
      ecommerce: {
        payment_type: paymentType
      }
    }

    window.dataLayer.push(checkoutPayload)
  }

  return {
    GA4TrackPaymentInfo
  }
}
