// https://github.com/SortableJS/Sortable
import Sortable from 'sortablejs'
import axios from 'axios'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    if (window.innerWidth > 767 && document.querySelector('.js-gallery-items')) {
      new Sortable(document.querySelector('.js-gallery-items'), {
        onStart: function (e) {
          e.item.classList.add('cursor-move')
          e.item.classList.add('opacity-50')
        },

        onEnd: function (e) {
          e.item.classList.remove('cursor-move')
          e.item.classList.remove('opacity-50')
        },

        onSort: function (e) {
          const el = e.item
          const position = e.newIndex
          const listItemId = el.getAttribute('data-gallery-item-id')
          const listId = el.getAttribute('data-gallery-id')
          el.classList.add('loading')

          function csrfToken() {
            const htmlElement = document?.querySelector('meta[name=csrf-token]')
            return htmlElement.content
          }

          const data = {
            list_id: listId,
            item_rank_position: position
          }

          axios.put(`/gallery_item/${listItemId}`, data, { headers: { 'X-CSRF-Token': csrfToken() } }).then(() => {
            el.classList.remove('loading')
          })
        }
      })
    }
  }
}
