import { Controller } from '@hotwired/stimulus'
import useCheckout from '../apps/main/src/hooks/useCheckout'
const { updateSummary } = useCheckout()

export default class extends Controller {
  static targets = ['billingAddress', 'toggle']

  connect() {
    this.toggleBillingAddress()
  }

  updateOrderSummary() {
    updateSummary()
  }

  toggleBillingAddress() {
    if (this.toggleTarget.checked) {
      this.billingAddressTarget.classList.add('hidden')
    } else {
      this.billingAddressTarget.classList.remove('hidden')
    }
  }

  selectPreviousAddress(ele) {
    const previousAddress = JSON.parse(ele.currentTarget.value)
    const fieldset = ele.currentTarget.name
    const addressFields = ['line_1', 'line_2', 'line_3', 'town', 'county', 'postcode', 'country_code', 'phone']

    addressFields.map((field) => {
      const key = `checkout_${fieldset}_address_${field}`
      const element = document.getElementById(key)
      element.value = previousAddress[field] ? previousAddress[field] : ''
    })

    if (fieldset === 'delivery') {
      updateSummary()
    }
  }
}
