<template>
  <div v-for="(section, section_index) in sections()" :key="section_index" class="mb-9">
    <div class="typeset-8 mb-3">{{ section.title }}</div>

    <div class="tags clamp-tags clamp-tags-1 clamp-tags-column">
      <a
        v-for="(tag, tag_index) in section.values"
        :id="`tag-${section_index + 1}-${tag_index + 1}`"
        :key="tag_index"
        :href="tag.url"
      >
        {{ tag.title }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onActivated } from 'vue'
import { clampTags } from '../../../../utils/tags'

onActivated(() => {
  clampTags(1, 'tag-black')
})

function sections() {
  return [
    {
      title: 'Popular artists',
      values: [
        { title: 'Henri Matisse', url: '/prints/henri-matisse' },
        { title: 'Andy Warhol', url: '/prints/andy-warhol' },
        { title: 'Mark Rothko', url: '/prints/mark-rothko' },
        { title: 'L S Lowry', url: '/prints/l-s-lowry' },
        { title: 'Keith Haring', url: '/prints/keith-haring' },
        { title: 'Pablo Picasso', url: '/prints/pablo-picasso' },
        { title: 'Jean-Michel Basquiat', url: '/prints/jean-michel-basquiat' },
        { title: 'Jackson Pollock', url: '/prints/jackson-pollock' },
        { title: 'David Hockney', url: '/prints/david-hockney' },
        { title: 'Frank Bowling', url: '/prints/frank-bowling' },
        { title: 'Lesley Birch', url: '/prints/lesley-birch' },
        { title: 'Faye Bridgwater', url: '/prints/faye-bridgwater' },
        { title: 'Hormazd Narielwalla', url: '/prints/hormazd-narielwalla' },
        { title: 'Ron Lawson', url: '/prints/ron-lawson' },
        { title: 'Vincent Van Gogh', url: '/prints/vincent-van-gogh' },
        { title: 'Gustav Klimt', url: '/prints/gustav-klimt' },
        { title: 'Claude Monet', url: '/prints/claude-monet' }
      ]
    },
    {
      title: 'Featured categories',
      values: [
        { title: 'New in', url: '/new' },
        { title: 'Contemporary', url: '/prints/contemporary' },
        { title: 'Modern Art', url: '/prints/modern-art' },
        { title: 'Photography', url: '/prints/photography' },
        { title: 'Iconic artists pre 1900', url: '/prints/pre-1900' },
        { title: 'Vintage', url: '/prints/vintage' },
        { title: 'Rare & Limited', url: '/prints/rare-limited' }
      ]
    },
    {
      title: 'Themes',
      values: [
        { title: 'Abstract', url: '/prints/abstract' },
        { title: 'Landscape', url: '/prints/landscape' },
        { title: 'Graphic', url: '/prints/graphic-illustration' },
        { title: 'Films', url: '/prints/films' },
        { title: 'Floral', url: '/prints/floral' },
        { title: 'Figurative', url: '/prints/figurative' },
        { title: 'Music', url: '/prints/music' },
        { title: 'Cityscape', url: '/prints/cityscape' },
        { title: 'Seascape', url: '/prints/seascape' },
        { title: 'Fashion', url: '/prints/fashion' },
        { title: 'Sport', url: '/prints/sport' },
        { title: 'Food & Drink', url: '/prints/food-drink' },
        { title: 'Travel', url: '/prints/travel' },
        { title: 'Line Art', url: '/prints/line-art' },
        { title: 'Street Art', url: '/prints/street-art' }
      ]
    },
    {
      title: 'Shape',
      values: [
        { title: 'Portrait', url: '/prints?shape=portrait' },
        { title: 'Landscape', url: '/prints?shape=landscape' },
        { title: 'Square', url: '/prints?shape=square' },
        { title: 'Tall', url: '/prints?shape=tall' },
        { title: 'Panoramic', url: '/prints?shape=panoramic' }
      ]
    },
    {
      title: 'Museums & Archives',
      values: [
        { title: 'Tate', url: '/prints/tate' },
        { title: 'V&A', url: '/prints/v-and-a' },
        { title: 'London Transport Museum', url: '/prints/london-transport-museum' },
        { title: 'Vogue', url: '/prints/vogue' },
        { title: 'The Lowry', url: '/prints/the-lowry' },
        { title: 'James Bond Collection', url: '/prints/james-bond-collection' },
        { title: 'Imperial War Museums', url: '/prints/imperial-war-museums' },
        { title: 'The National Gallery', url: '/prints/national-gallery' },
        { title: 'National Galleries of Scotland', url: '/prints/national-galleries-of-scotland' },
        { title: 'National Portrait Gallery', url: '/prints/national-portrait-gallery' },
        { title: 'The Courtauld Gallery', url: '/prints/the-courtauld-gallery' },
        { title: 'Royal Horticultural Society', url: '/prints/royal-horticultural-society' },
        { title: 'Royal Academy of Arts', url: '/prints/royal-academy-of-arts' },
        { title: 'The Hepworth Wakefield', url: '/prints/hepworth-wakefield' }
      ]
    },
    {
      title: 'Size',
      values: [
        { title: 'Mini', url: '/prints?available_sizes=Mini' },
        { title: 'Small', url: '/prints?available_sizes=Small' },
        { title: 'Medium', url: '/prints?available_sizes=Medium' },
        { title: 'Large', url: '/prints?available_sizes=Large' },
        { title: 'X Large', url: '/prints?available_sizes=X+Large' },
        { title: 'XX Large', url: '/prints?available_sizes=XX+Large' }
      ]
    },
    {
      title: 'Movement',
      values: [
        { title: 'Pop Art', url: '/prints/pop-art' },
        { title: 'Art Deco', url: '/prints/art-deco' },
        { title: 'Bauhaus', url: '/prints/bauhaus' },
        { title: 'Impressionism', url: '/prints/impressionism' },
        { title: 'Post-Impressionism', url: '/prints/post-impressionism' },
        { title: 'Graffiti Art', url: '/prints/graffiti-art' },
        { title: 'Cubism', url: '/prints/cubism' },
        { title: 'Asian Art', url: '/prints/asian-art' },
        { title: 'Mid Century British', url: '/prints/mid-century-british' },
        { title: 'Surrealism & Dada', url: '/prints/surrealism' },
        { title: 'Pre-Raphaelite', url: '/prints/pre-raphaelite' },
        { title: 'Art Nouveau', url: '/prints/art-nouveau' }
      ]
    },
    {
      title: 'Popular',
      values: [
        { title: 'Gift vouchers', url: '/gift-vouchers' },
        { title: 'Gifts', url: '/prints/gifts' },
        { title: 'Stationery', url: '/prints/stationery' }
      ]
    },
    {
      title: 'Rare & Limited',
      values: [
        { title: 'Ceramics', url: '/ceramics' },
        { title: 'Rare Posters', url: '/prints/rare-posters' },
        { title: 'Limited Editions', url: '/prints/limited-editions' },
        { title: 'Unique', url: '/prints/unique' }
      ]
    },
    {
      title: 'Colour',
      values: [
        { title: 'Black and white', url: '/prints/black-white' },
        { title: 'Blue', url: '/prints/blue' },
        { title: 'Green', url: '/prints/green' },
        { title: 'Grey', url: '/prints/grey' },
        { title: 'Purple', url: '/prints/purple' },
        { title: 'Pink', url: '/prints/pink' },
        { title: 'Red', url: '/prints/red' },
        { title: 'Yellow', url: '/prints/yellow' },
        { title: 'Orange', url: '/prints/orange' },
        { title: 'Gold', url: '/prints/gold' },
        { title: 'Multicolour', url: '/prints/multicolour' }
      ]
    }
    // {
    //   title: 'Price',
    //   values: [
    //     { title: 'High to low', url: '/prints?sort=price:desc' },
    //     { title: 'low to high', url: '/prints?sort=price:asc' }
    //   ]
    // }
  ]
}
</script>
