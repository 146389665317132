import { Controller } from '@hotwired/stimulus'
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js'
import { loadScript } from '@paypal/paypal-js'

import useCheckout from '../apps/main/src/hooks/useCheckout'
import useGA4Helper from '../apps/main/src/hooks/useGA4Helper'

const { GA4TrackPaymentInfo } = useGA4Helper()
const { updateSummary } = useCheckout()

export default class extends Controller {
  connect() {
    loadScript({ 'client-id': window.KM.Config.paypal.client_id, currency: 'GBP' }).then((paypal) => {
      paypal
        .Buttons({
          createOrder: this.createOrder.bind(this),
          onShippingChange: this.onShippingChange.bind(this),
          onApprove: this.onApprove.bind(this),
          onCancel: this.onCancel.bind(this),
          onError: this.onError.bind(this),
          style: {
            layout: 'horizontal',
            tagline: false
          }
        })
        .render('#paypal-button-container')
    })
  }

  csrfToken() {
    return document.querySelector('meta[name=csrf-token]').content
  }

  createOrder(_data, _actions) {
    GA4TrackPaymentInfo('PayPal')

    return fetch('/checkout/paypal', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      }
    })
      .then(function (response) {
        if (response.ok) {
          return response.json()
        }

        throw new Error(response.statusText)
      })

      .then(function (data) {
        if (data.id) {
          window.approve_url = data.links[1].href
          return data.id
        }

        throw new Error('bad payment id')
      })
  }

  onShippingChange(data, actions) {
    if (!data.shipping_address || !data.shipping_address.country_code) {
      throw new Error('shipping error')
    }

    if (data.shipping_address.country_code === 'GB') {
      const nonMainlandStates = ['IslesofScilly', 'Orkney', 'Shetland', 'WesternIsles']
      if (nonMainlandStates.includes(data.shipping_address.state)) {
        data.shipping_address.country_code = 'XA'
      }

      if (data.shipping_address.state == 'IsleofMan') {
        data.shipping_address.country_code = 'IM'
      }

      if (data.shipping_address.state == 'ChannelIslands') {
        data.shipping_address.country_code = 'XB'
      }
    }

    if (data.shipping_address.country_code === 'C2') {
      data.shipping_address.country_code = 'CN'
    }

    return fetch('/checkout/paypal', {
      method: 'put',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      },
      body: JSON.stringify({
        checkout: {
          delivery_address: {
            country_code: data.shipping_address.country_code
          }
        },
        paypal_order_id: data.orderID
      })
    })
      .then(async function (response) {
        // refresh underlying page to reflect new delivery country
        const countryCode = document.getElementById('checkout_delivery_address_country_code')
        if (countryCode && data.shipping_address) {
          countryCode.value = data.shipping_address.country_code
        }
        updateSummary()

        if (response.ok) {
          return response
        }
        const json = await response.json()
        throw new Error(json)
      })
      .catch(function (_) {
        return actions.reject()
      })
  }

  onApprove(data, actions) {
    return fetch('/payment/paypal', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        'X-CSRF-Token': this.csrfToken()
      },
      body: JSON.stringify({
        transaction_id: data.orderID
      })
    }).then(function (response) {
      response.json().then(function (orderData) {
        // Cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Recoverable ORDER_NOT_APPROVED -> redirect entire page for approval by customer
        //   (4) Duplicate invoice -> something happened but payment likely to have succeeded
        //   (5) Other non-recoverable errors -> Show a failure message
        //   (6) Successful transaction -> Show confirmation or thank you

        const errorDetail = Array.isArray(orderData.details) && orderData.details[0]

        // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
          return actions.restart()
        }

        if (errorDetail && errorDetail.issue === 'ORDER_NOT_APPROVED') {
          return (window.location.href = window.approve_url)
        }

        if (errorDetail && errorDetail.issue === 'DUPLICATE_INVOICE_ID') {
          return window.location.reload()
        }

        if (errorDetail) {
          this.showError(errorDetail.description)
          return
        }

        window.location.href = `/checkout/complete/${orderData.order_id}`
      })
    })
  }

  onCancel(_data) {
    // Send GA4 event
  }

  onError(_error) {
    const message = `
      <h4>An error occurred processing your payment</h4>
      <p>If this problem persists please contact us for help.</p>
    `
    this.showError(message)
  }

  showError(errorMessage) {
    Fancybox.show([
      {
        src: errorMessage,
        type: 'inline'
      }
    ])
  }
}
