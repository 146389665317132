import useHttps from './useHttps'
const { get } = useHttps()

export default function useSearch() {
  async function fetchArtists(text: string) {
    const params = new URLSearchParams()
    params.append('s', text)
    params.append('per_page', '6')

    const url = `/api/artists?${params.toString()}`
    return get(url).then((res) => res)
  }

  async function fetchArtworks(text: string) {
    const params = new URLSearchParams()
    params.append('s', text)
    params.append('per_page', '6')

    const url = `/api/artworks?${params.toString()}`
    return get(url).then((res) => res)
  }

  async function fetchCategories(text: string) {
    const params = new URLSearchParams()
    params.append('s', text)
    params.append('per_page', '6')
    params.append('sort', 'num_artworks:desc')

    const url = `/api/categories?${params.toString()}`
    return get(url).then((res) => res)
  }

  async function fetchPages(text: string) {
    const params = new URLSearchParams()
    params.append('s', text)

    const url = `/api/tags?${params.toString()}`
    return get(url).then((res) => res)
  }

  return {
    fetchArtists,
    fetchArtworks,
    fetchCategories,
    fetchPages
  }
}
