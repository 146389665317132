import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import SearchApp from '../apps/main/src/SearchApp.vue'

export default class extends Controller {
  static targets = ['search_box']

  open() {
    let searchAppElement
    if ((searchAppElement = document.querySelector('SearchApp'))) {
      const app = createApp(SearchApp)
      app.mount(searchAppElement)
    }
  }

  doSearch(e) {
    if (this.search_boxTarget.value == '') {
      e.preventDefault()
    }
  }
}
