import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "typeset-8 mb-3" }
const _hoisted_2 = { class: "tags clamp-tags clamp-tags-1 clamp-tags-column" }
const _hoisted_3 = ["id", "href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sections(), (section, section_index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: section_index,
      class: "mb-9"
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(section.title), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.values, (tag, tag_index) => {
          return (_openBlock(), _createElementBlock("a", {
            id: `tag-${section_index + 1}-${tag_index + 1}`,
            key: tag_index,
            href: tag.url
          }, _toDisplayString(tag.title), 9, _hoisted_3))
        }), 128))
      ])
    ]))
  }), 128))
}