import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cartForm', 'offerCodeBox', 'offerCodeInput']

  changeCountry(ele) {
    ele.currentTarget.classList.add('loading')
    this.submitForm()
  }

  submitForm() {
    this.cartFormTarget.submit()
  }

  toggleOfferCode() {
    this.offerCodeBoxTarget.classList.toggle('hidden')
  }

  updateOfferCode() {
    this.offerCodeInputTarget.value = this.offerCodeInputTarget.value.toUpperCase()
  }
}
