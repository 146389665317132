import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'source', 'topyenoh', 'email']

  getCsrfToken() {
    const tokenElement = document.querySelector('meta[name=csrf-token]')
    return tokenElement.content
  }

  handleErrors(response) {
    if (response.ok) {
      return response
    } else {
      throw Error(response.statusText)
    }
  }

  submitForm() {
    const body = {
      email: this.emailTarget.value,
      source: this.sourceTarget.value,
      topyenoh: this.topyenohTarget.value,
      authenticity_token: this.getCsrfToken()
    }

    this.messageTarget.classList.remove('flash-alert')
    this.messageTarget.classList.add('bg-green')
    this.messageTarget.innerHTML = 'Please wait...'

    fetch('/mailing_list', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then(this.handleErrors)
      .then(() => {
        this.messageTarget.innerHTML = 'Thank you!'
      })
      .catch(() => {
        this.messageTarget.classList.remove('bg-green')
        this.messageTarget.classList.add('flash-alert')
        this.messageTarget.innerHTML = "We're sorry but something went wrong. Please try again later."
      })
  }
}
