import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['countLabel', 'inputToTrack']
  static values = { max: Number, selector: String }

  connect() {
    this.update()
  }

  update() {
    this.count = this.inputToTrackTarget.value.length
    this.countLabelTarget.innerHTML = `${this.count}/${this.maxValue}`
  }
}
