import { Controller } from '@hotwired/stimulus'

const CARD_ICONS_BASE_URL = '/assets/checkout/card-icons'

export default class extends Controller {
  static targets = [
    'authError',
    'authSuccess',
    'cardError',
    'cardNumberError',
    'cvvError',
    'error',
    'expiryDateError',
    'expiryError',
    'payButton',
    'paymentMethodLogo'
  ]

  logos = {
    'card-number': {
      src: 'card',
      errorText: 'Please enter a valid card number'
    },
    'expiry-date': {
      src: 'exp-date',
      errorText: 'Please enter a valid expiry date'
    },
    cvv: {
      src: 'cvv',
      errorText: 'Please enter a valid cvv code'
    }
  }

  connect() {
    // https://www.checkout.com/docs/integrate/frames/frames-customization-guide
    window.Frames.init({
      publicKey: KM.Config.checkout_com.public_key,
      cardTokenizationFailed: this.cardTokenizationFailed.bind(this),
      cardTokenized: this.cardTokenized.bind(this),
      frameValidationChanged: this.frameValidationChanged.bind(this),
      paymentMethodChanged: this.paymentMethodChanged.bind(this),
      cardValidationChanged: this.cardValidationChanged.bind(this),
      // https://www.checkout.com/docs/integrate/frames/frames-customization-guide#Custom_CSS
      style: {
        base: {
          color: '#1a1a1a',
          backgroundColor: '#eae9e7',
          height: '48px',
          fontSize: '16px',
          paddingLeft: '10px'
        },
        placeholder: {
          base: {
            color: '#eae9e7'
          }
        }
      }
    })
  }

  cardTokenizationFailed() {
    console.log('cardTokenizationFailed')
    window.Frames.enableSubmitForm()
  }

  showError(text) {
    console.log('showError')
    this.errorTarget.innerHTML = text
    this.errorTarget.classList.remove('hidden')
    this.payButtonTarget.disabled = true
  }

  clearError() {
    console.log('clearError')
    this.errorTarget.innerHTML = ''
    this.errorTarget.classList.add('hidden')
  }

  cardTokenized(response) {
    console.log('cardTokenized')
    const message = 'Authorising payment. Please wait...'
    this.authSuccessTarget.innerHTML = message
    this.authSuccessTarget.classList.remove('hidden')

    const showError = (text) => {
      this.errorTarget.innerHTML = text
      this.errorTarget.classList.remove('hidden')
      this.payButtonTarget.disabled = true
    }

    fetch('/payment/checkout_com', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken()
      },
      body: JSON.stringify({ checkout_response: response })
    })
      .then((response) => {
        if (response.ok) {
          this.authSuccess = true
          return response.json()
        } else {
          showError(response.statusText)
        }
      })
      .then((result) => {
        if (result && result.redirect_url) {
          window.location = result.redirect_url
        } else {
          showError(result.error)
          window.Frames.enableSubmitForm()
        }
      })
  }

  frameValidationChanged(event) {
    console.log('frameValidationChanged')
    this.clearError()

    const el = event.element
    switch (el) {
      case 'expiry-date':
        if (event.isValid) {
          this.expiryDateErrorTarget.classList.add('hidden')
        } else {
          this.expiryDateErrorTarget.classList.remove('hidden')
          this.showError('Please enter a valid expiry date')
        }
        break
      case 'cvv':
        if (event.isValid) {
          this.cvvErrorTarget.classList.add('hidden')
        } else {
          this.cvvErrorTarget.classList.remove('hidden')
          this.showError('Please enter a valid cvv code')
        }
        break
      case 'card-number':
        if (event.isValid) {
          this.cardNumberErrorTarget.classList.add('hidden')
          this.paymentMethodLogoTarget.classList.remove('hidden')
        } else {
          this.cardNumberErrorTarget.classList.remove('hidden')
          this.paymentMethodLogoTarget.classList.add('hidden')
          this.showError('Please enter a valid card number')
        }
        break
    }
  }

  paymentMethodChanged(e) {
    if (e.isPaymentMethodAccepted && e.paymentMethod) {
      this.paymentMethodLogoTarget.src = `${CARD_ICONS_BASE_URL}/${e.paymentMethod.toLowerCase()}.svg`
    }
  }

  cardValidationChanged(e) {
    console.log('cardValidationChanged')
    this.payButtonTarget.disabled = !e.isValid
    window.Frames.enableSubmitForm()
    this.errorTarget.classList.add('hidden')
  }

  getCsrfToken() {
    const tokenElement = document.querySelector('meta[name=csrf-token]')
    return tokenElement.content
  }

  submit() {
    console.log('submit')
    try {
      this.payButtonTarget.disabled = true
      window.Frames.submitCard()
    } catch (e) {
      console.log('checkout-com: Failed to Submit', e)
    } finally {
      this.payButtonTarget.disabled = false
    }
  }
}
