import useHttps from './useHttps'
import useDomHelper from './useDomHelper'

export default function useCheckout(): {
  updateSummary: () => void
} {
  async function updateSummary() {
    const { get } = useHttps()
    const summaryContainers = [
      '#delivery-options-container',
      '#order-items-container',
      '#order-summary-container',
      '#payment-type-klarna',
      '#continue-button-container'
    ]
    const { findAndReplace, setLoading } = useDomHelper()

    const form: HTMLFormElement | null = document.querySelector('[data-form-checkout]')
    if (form) {
      const formData = new FormData(form)
      const params = new URLSearchParams(formData as any).toString()
      setLoading(summaryContainers, true)
      get(`/checkout?${params}`, { headers: { Accept: 'text/html' } })
        .then((data) => {
          findAndReplace(data, summaryContainers)
        })
        .finally(() => {
          setLoading(summaryContainers, false)
        })
    }
  }
  return {
    updateSummary
  }
}
