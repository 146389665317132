import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'open', 'close']

  connect() {
    this.closeTarget.classList.add('hidden')
  }

  open() {
    this.contentTarget.classList.remove('hidden')
    this.openTarget.classList.add('hidden')
    this.closeTarget.classList.remove('hidden')
  }

  close() {
    this.contentTarget.classList.add('hidden')
    this.openTarget.classList.remove('hidden')
    this.closeTarget.classList.add('hidden')
  }
}
